import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export function useContactFormData({ id }) {
  const { formDataArr } = useStaticQuery(graphql`
    query {
      formDataArr: allStoryblokEntry(
        filter: { field_component: { eq: "contactForm" } }
      ) {
        edges {
          node {
            name
            slug
            content
          }
        }
      }
    }
  `);

  return useMemo(() => {
    return formDataArr.edges
      .map((v) => {
        return JSON.parse(v.node.content);
      })
      .find((v) => v.id === id);
  }, [formDataArr, id]);
}
