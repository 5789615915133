import React from 'react';

import PageStatic from '../../components/page-static';
import SiteSupportForm from '../../components/contact/site-support-form';

function StartProjectPage(props) {
  return (
    <PageStatic seoTitle={'Contact us. Site Support'} {...props} noCtaBlock>
      <SiteSupportForm />
    </PageStatic>
  );
}

export default StartProjectPage;
