import React from 'react';

import { ContactScreen } from './shared-components';
import Container from '../container';
import Header from './header';
import { useContactFormData } from '../../hooks/use-contact-form-data';
import HubSpotForm from '../hubspot-form';

function ContactFormScreenBase({ dataId }) {
  const formCmsData = useContactFormData({ id: dataId });
  const { title, portalId, formId } = formCmsData;

  return (
    <ContactScreen>
      <Container narrow>
        <Header title={title} />
        <HubSpotForm portalId={portalId} formId={formId} altStyling />
      </Container>
    </ContactScreen>
  );
}

export default ContactFormScreenBase;
